(function( $ ) {

	let $toggles = $('.js-content-toggle');

	if ( !$toggles.length ) {
		return;
	}

	let $toggleTargets = $toggles.map(function() {
		let $target = $( $(this).attr('data-targets') );

		if ( $target.length ) {
			return $target.get();
		}

		return false;
	});

	$toggles.click(function(event) {
		$toggleTargets.prop('hidden', 'true');

		let $target = $( $(this).attr('data-targets') );

		if ( $target.length ) {
			$target[0].hidden = false;

			$toggles.attr('aria-pressed', 'false');
			$(this).attr('aria-pressed', 'true');

			$target.find('.js-image-carousel').slick('setPosition');
		}
	});
	
})( jQuery );
