// Initialise Slick slider

( function( $, window, document, undefined ) {

	// Uncomment to use SVG icon buttons
	// const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.88 25.99" aria-hidden="true" focusable="false"><path d="M14.37.51a1.74,1.74,0,0,0-2.45,0h0L.41,12a1.38,1.38,0,0,0,0,2h0l11.5,11.51A1.74,1.74,0,1,0,14.37,23l-10-10,10-10A1.73,1.73,0,0,0,14.37.51Z"/></svg></button>`;
	// const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.88 25.99" aria-hidden="true" focusable="false"><path d="M.51,25.48a1.74,1.74,0,0,0,2.45,0H3L14.47,14a1.38,1.38,0,0,0,0-2h0L3,.51A1.73,1.73,0,0,0,.51,3l10,10L.5,23A1.73,1.73,0,0,0,.51,25.48Z"/></svg></button>`;


	const $imageCarousel = $('.js-image-carousel');

	if ( $imageCarousel.length ) {

		$imageCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: false,
			// prevArrow: prevArrowHTML,
			// nextArrow: nextArrowHTML,
			infinite: true,
			adaptiveHeight: false,
			draggable: true
		} );

	} // /$imageCarousel.length
	
} )( jQuery, window, document );
